<template>
  <div class="index">
    <h1>404 page</h1>
    <router-link to="/index">回到首页</router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage',
  data() {
    return {
      redirectedFrom: '',
    }
  },
  created() {
    this.redirectedFrom = this.$route?.redirectedFrom || '';
    console.log('redirectedFrom: ', this.redirectedFrom);
  },
}
</script>
